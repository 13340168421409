import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: '/home'
  }, {
    path: '/home',
    name: 'home',
    component: () => import('../views/homePage.vue')
  }, {
    path: '/service',
    name: 'loanService',
    component: () => import('../views/loanService.vue')
  }, {
    path: '/about',
    name: 'aboutUS',
    component: () => import('../views/aboutUS.vue')
  }, {
    path: '/contact',
    name: 'contactUS',
    component: () => import('../views/contactUS.vue')
  }, {
    path: '/loanPolicy',
    name: 'loanPolicy',
    component: () => import('../views/loanPolicy.vue')
  }, {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('../views/privacyPolicy.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router;
